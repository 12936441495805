import styles from "./styles.module.scss";
import { TicketData, TicketSale, TopTitle } from "components";
import { arrow, lineup } from "assets";

const TicketPurchaseUI = () => {
  const turkeyTickets: TicketData[] = [
    {
      id: "nov-10",
      month: "Nov",
      date: "10",
      day: "Sunday",
      time: "10:00 PM",
      venue: "1 OAK, SISLI ESENTEPE, KORE SEHITLERI CD. NO:48-50",
      name: "DJames",
      available: "open",
      validVenue: true,
      ticketOptions: ["Single - ₺500",],
      url: "https://www.biletimgo.com/etkinlik/djames-1-oak-istanbul-9021" // url for this specific ticket
    },
   // {
   //   id: "aug-25",
   //   month: "Aug",
   //   date: "25",
   //   day: "Sunday",
   //   time: "10: 00 PM",
   //   venue: "1 OAK, SISLI ESENTEPE, KORE SEHITLERI CD. NO:48-50",
   //   name: "TXC: Amapiano Asylum",
   //   available: "open",
   //   validVenue: true,
   //   ticketOptions:[  "Single - ₺500", "Couple - ₺1500"],
   //   url: "https://www.biletimgo.com/etkinlik/tcx-7499" // url for this specific ticket
   //   
   // },
   // {
   //  id: "sep-22",
   //  month: "SEP",
   //  date: "22",
   //  day: "Friday",
   //  time: "10: 00 PM",
   //  venue: "Vibes City  - Magusa ",
   //  name: "Mellow & Sleezy: Amapiano Asylum 2.0",
   //  available: "closed",
   //  validVenue: true,
   //},
   //{
   //  id: "sep-23",
   //  month: "SEP",
   //  date: "23",
   //  day: "Saturday",
   //  time: "10: 00 PM",
   //  venue: "Club Cheetah  - Girne ",
   //  name: "Busta 929: Amapiano Asylum 2.0",
   //  available: "closed",
   //  validVenue: true,
   //},
  ];
  const cyprusTickets: TicketData[] = [
    //{
    //  id: "3",
    //  month: "JUL",
    //  date: "06",
    //  day: "Saturday",
    //  time: "10: 00 PM",
    //  venue: "Club Cheetah Girne",
    //  name: "TitoM & Yuppe: Amapiano Asylum",
    //  available: "open",
    //  validVenue: true,
    //  ticketOptions:[  "Early Bird - ₺300",]
    //},
   //{
   //  id: "4",
   //  month: "JUL",
   //  date: "20",
   //  day: "Saturday",
   //  time: "10: 00 PM",
   //  venue: "Club Cheetah Girne",
   //  name: "Zee Nkumalo: Amapiano Asylum",
   //  available: "open",
   //  validVenue: true,
   //  ticketOptions:[  "Early Bird - ₺500",]
   //},
   //{
   //  id: "5",
   //  month: "TBA",
   //  date: "TBA",
   //  day: "TBA",
   //  time: "10: 00 PM",
   //  venue: "Cemsa Karting & Sporting Center Lefkosa",
   //  name: "Seyi Vibez: Afro Wave",
   //  available: "soon",
   //  validVenue: true,
   //  ticketOptions:[  "Early Bird - ₺500","Bistro (4) - $800","VIP Table (10) - $1500","VIP Table (12) - $2000"]
   //},
   {
    id: "nov-9",
    month: "Nov",
    date: "09",
    day: "Saturday",
    time: "10:00 PM",
    venue: "Crystal Club Girne",
    name: "DJames",
    available: "open",
    validVenue: true,
    ticketOptions: ["Regular - ₺400", "VIP - $500"],
    //url: "https://www.biletimgo.com/etkinlik/mr-jazziq-7498" // url for this specific ticket
  },
  ];
  return (
    <div>
      <div className={styles.top}>
        <TopTitle text={"GRAB UR TICKET"} position={true} />
      </div>
      {/*
      <div className={styles.lineupDiv}>
        <img src={arrow} alt="" className={styles.arrow} />
        <img src={lineup} alt="" className={styles.lineupDiv__img} />
        <img src={arrow} alt="" className={styles.arrow} />
      </div> 
      */}
      <div className={styles.ticketSale}>
        <div className={styles.ticketSale__location}>
          <h1>Cyprus</h1>
          {cyprusTickets.map((ticket) => (
            <TicketSale {...ticket} />
          ))}
        </div>

        <div className={styles.ticketSale__location}>
          <h1>Turkey</h1>
          {turkeyTickets.map((ticket) => (
            <TicketSale {...ticket} />
          ))}
        </div>
      </div>
    </div>
  );
};

export { TicketPurchaseUI };
